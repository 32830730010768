const data = [
  {
    path: '/about',
    label: 'header.about',
  },
  {
    path: '/resume',
    label: 'header.resume',
  },
  {
    path: '/stats',
    label: 'header.stats',
  },
  {
    path: '/contact',
    label: 'header.contact',
  },
];

export default data;
