
export const colors = [
  '#6968b3',
  '#37b1f5',
  '#40494e',
  '#515dd4',
  // '#e47272',
  // '#cc7b94',
  '#3896e2',
  // '#c3423f',
  // '#d75858',
  '#747fff',
  '#64cb7b',
];
