const data = [
    {
        locale: 'pt',
        flag: '🇧🇷',
    },
    {
        locale: 'en',
        flag: '🇺🇸',
    },
];

export default data;
